<template>
  <div 
    ref="textRef" 
    class="mm-news-text"
    :class="{ 'left-title' : body.leftTitle }"
  >
    <div 
      v-for="text in newsService.getDisplayedText(body.bodyRichText)"
      :key="text"
      v-html="sanitize(text)"
    />
  </div>
</template>

<script lang="ts" setup>
import { IStrapiNewsDynamicBody } from 'models/strapi/strapiNews.model';
import { NewsService } from 'services/news/news.service';
import { sanitize } from 'shared/directives/sanitize';

defineProps<{
  body: IStrapiNewsDynamicBody;
}>();
const newsService: NewsService = inject(NewsService.getServiceName());
const textRef = ref();

onBeforeMount(() => {
  nextTick(() => {
    textRef.value = newsService.generateListWrapperElement(textRef.value, 'ul');
    textRef.value = newsService.generateListWrapperElement(textRef.value, 'ol');
  });
});
</script>

<style lang="scss" scoped>
@import 'styles/base/common/variables';

.mm-news-text {
  margin-top: 24px;
  margin-bottom: 24px;

  &:has(h1) {
    &:has(p) {
      :deep(h1) {
        font-size: 32px;
        line-height: 36px;
        margin-bottom: 0;
      }
    }
  }

  &:has(h4) {
    > div {
      &:nth-child(2) {
        margin-top: 0;
      }
    }

    &:deep(h4) {
      margin-bottom: 12px;
      font-size: 20px;
      line-height: 28px;
    }
  }

  &.left-title {
    display: flex;
    gap: 28px;
    width: 100%;

    :deep(div) {
      width: 50%;
      margin-top: 0;

      &:first-child {
        > * {
          font-size: 32px;
          line-height: 36px;
        }
      }
    }
  }

  :deep(a) {
    color: $link;
    text-decoration: none;
  }

  :deep(.mm-list-wrapper-ul), :deep(.mm-list-wrapper-ol) {
    margin-left: 20px;
    padding-left: unset;
    margin-bottom: 0;
    margin-top: 24px;

    li {
      color: $light-green;
      margin-bottom: 4px;

      &::marker {
        color: $link;
        font-size: 1.25em;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  :deep(.mm-list-wrapper-ol) {
    > li {
      &::marker {
        color: $light-green;
        font-size: unset;
      }
    }
  }
  
  div {
    :deep(p) {
      margin-bottom: 0;
      color: $light-green;
    }
    
    margin-top: 24px;
    
    &:first-child {
      margin-top: 0;
    }
  }
}

@media screen and (max-width: 1279px) {
  .mm-news-text {
    margin-top: 20px;
    margin-bottom: 20px;

    &:has(h1) {
      &:has(p) {
        :deep(h1) {
          font-size: 24px;
          line-height: 32px;
        }
      }
    }

    div {
      margin-top: 20px;

      :deep(p) {
        font-size: 14px;
        line-height: 20px;
      }
    }

    :deep(.mm-list-wrapper-ul), :deep(.mm-list-wrapper-ol) {
      margin-top: 20px;

      li {
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 8px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    &:has(h4) {
      &:deep(h4) {
        margin-bottom: 8px;
        font-size: 16px;
        line-height: 24px;
      }
    }

    &.left-title {
      gap: 0;
      flex-direction: column;

      :deep(div) {
        width: 100%;
        margin-top: 0;

        &:first-child {
          > * {
            font-size: 24px;
            line-height: 32px;
            margin-bottom: 20px;
          }
        }
      }
    }
  }
}
</style>