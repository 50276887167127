<template>
  <div class="mm-news-text-quote">
    <div
      v-for="text in newsService.getDisplayedText(body.quoteText ?? '')" 
      :key="text" 
      class="mm-news-text-quote--text"
      v-html="sanitize(text)"
    />

    <div class="mm-news-text-quote--author">
      <BaseImg 
        :src="newsService.getImage(body.quotePicture?.data?.attributes)"
      />
      <div class="mm-news-text-quote--author__inner">
        <p class="mm-news-text-quote--author__inner--title">
          {{ body.quoteAuthor }}
        </p>
        <span class="mm-news-text-quote--author__inner--caption">
          {{ body.quoteCaption }}
        </span>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { IStrapiNewsDynamicBody } from 'models/strapi/strapiNews.model';;
import BaseImg from 'shared/components/BaseImg.vue';
import { NewsService } from 'services/news/news.service';
import { sanitize } from 'shared/directives/sanitize';

defineProps<{
  body: IStrapiNewsDynamicBody;
}>();
const newsService: NewsService = inject(NewsService.getServiceName());
</script>

<style lang="scss" scoped>
@import 'styles/base/common/variables';

.mm-news-text-quote {
  background-color: $light-gray;
  border-radius: 8px;
  padding: 32px;
  margin-top: 40px;
  margin-bottom: 40px;

  &--text {
    margin-bottom: 24px;

    :deep(p) {
      margin-bottom: 0;
      font-weight: 500;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &--author {
    display: flex;
    align-items: center;
    gap: 16px;

    img {
      width: 60px;
      height: 60px;
    }

    &__inner {
      display: flex;
      flex-direction: column;

      &--title {
        margin-bottom: 4px;
      }

      &--caption {
        font-weight: 400;
        color: $light-green;
        font-size: 14px;
        line-height: 20px;  
      }
    }
  }
}

@media screen and (max-width: 1279px) {
  .mm-news-text-quote {
    margin-top: 32px;
    margin-bottom: 32px;
    padding: 24px;

    &--text {
      margin-bottom: 20px;

      :deep(p) {
        font-size: 14px;
        line-height: 20px;
      }
    }

    &--author {
      img {
        width: 40px;
        height: 40px;
      }

      &__inner {
        &--title {
          font-size: 14px;
          line-height: 20px;
        }

        &--caption {
          font-size: 12px;
          line-height: 16px;  
        }
      }
    }
  }
}
</style>