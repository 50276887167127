<template>
  <div class="mm-news-media">
    <BaseImg
      v-if="image"
      :src="image"
      class="mm-news-list__news__image"
    />
    <div
      v-for="text in newsService.getDisplayedText(body.bodyMediaCaption)" 
      :key="text"
      v-html="sanitize(text)" 
    />
  </div>
</template>

<script lang="ts" setup>
import { IStrapiNewsDynamicBody } from 'models/strapi/strapiNews.model';
import { NewsService } from 'services/news/news.service';
import { sanitize } from 'shared/directives/sanitize';

const props = defineProps<{
  body: IStrapiNewsDynamicBody;
}>();

const newsService: NewsService = inject(NewsService.getServiceName());
const image = computed(() => newsService.getImage(props.body?.bodyMedia?.data?.attributes));
</script>

<style lang="scss" scoped>
@import 'styles/base/common/variables';

.mm-news-media {
  max-width: 100%;
  margin-top: 40px;
  margin-bottom: 40px;

  img {
    width: 100%;
  }

  div {
    margin-top: 12px;

    :deep(p) {
      margin-bottom: 0;
      color: $light-green;
    }
  }
}

@media screen and (max-width: 1279px) {
  .mm-news-media {
    margin-top: 32px;
    margin-bottom: 32px;

    div {
      margin-top: 8px;
      font-size: 14px;
      line-height: 20px;
    }
  }
}
</style>