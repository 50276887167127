import showdown from 'showdown';

export class ShowdownService {
  private static converter = new showdown.Converter();

  constructor() {
    ShowdownService.converter.setOption('strikethrough', true);
    ShowdownService.converter.setFlavor('github');
  }

  public static getText(text: string) {
    return this.converter.makeHtml(text);
  }
}